#header_wrapper {
    position: absolute;
    z-index: 1;
    padding: 3rem;
    padding-right: 3rem;
    padding-left: .5rem;

    display: flex;
    justify-content: space-between;
    width: 100%;

    text-transform: uppercase;

    & li{
        white-space: nowrap;
    }
}

#header_wrapper>ul {
    display: flex;
    width: clamp(38%, 10vw, 35%);
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    list-style: none;
}

@media screen and (width < 960px) {
    #header_wrapper>ul {
        flex-direction: column;
        align-items: flex-start;
        width: 55%;
    }
}


.status {
    display: flex;
    justify-content: center;
    align-items: center;
}

.status-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #31ee33;
    margin-right: .8rem;
    position: relative;


    &::before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: #31ee33;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: .5;


        animation: growing-dot 2s ease-in-out infinite;
        transition: all 0.5s linear;

        transform: translate(-50%, -50%);
    }
}

@keyframes growing-dot {

    0%,
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }

    50% {
        transform: translate(-50%, -50%);
        opacity: .5;
    }

    75% {
        transform: translate(-50%, -50%) scale(5);
        opacity: 0;
    }
}