/* _____________ */

.general_card_cover {
    position: absolute;
    right: 1rem;
    bottom: 18rem;
    padding: 15rem 0 0;
    overflow: hidden;
    /* border: solid red; */
}

@media screen and (width < 768px) {
    .general_card_cover {
        scale: .85;
        transform: translateY(45%);
        z-index: 6;
    }
}

.contact_card_body {
    position: relative;
    background-color: var(--dark-color-landing);
    padding: 1.8rem 1.5rem;
    width: 300px;
    border-radius: var(--border-radius);
    border: 1px solid rgba(var(--dark-color), 0.1);
    color: rgb(var(--dark-color));
}

.contact_card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    margin: 1rem 0 0;

    & p {
        margin: 0;
        text-align: left;
    }
}

.contact_card_socials {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0.5rem;
    margin: 1rem 0;
    cursor: pointer;

    & :hover {
        scale: 0.99;
    }
}

.socials_card {
    --border-radius: 5px;
    border: 1px solid rgba(var(--dark-color), 0.1);
    padding: 1rem;
    border-radius: var(--border-radius);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.socials_card:nth-child(3) {
    border: 1px solid rgba(var(--dark-color), 0.1);

    grid-column: span 2;
}

.contact_card_close {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

/* _____________ */

.about_card_body {
    position: absolute;
    background-color: var(--dark-color-landing);
    padding: 1.8rem 1.5rem;
    width: 300px;
    border-radius: var(--border-radius);
    border: 1px solid rgba(var(--dark-color), 0.1);
    color: rgb(var(--dark-color));
}

.about_card {
    display: flex;
    flex-direction: column;
    font-size: smaller;
    align-items: flex-start;
    gap: 1rem;
    margin: 1rem 0 0;
    text-transform: uppercase;
    line-height: 1.3;

    & p {
        margin: 0;
        text-align: left;
    }
}

.about_card_title {
    white-space: nowrap;
}

.about_card_close {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.ach_card {
    background-color: rgb(var(--light-color));
    width: max-content;
    padding: 0.2rem 1rem;
    color: rgb(var(--dark-color));
    text-align: start;
    border-radius: 0.1rem;
}

.about_card_avatar {
    position: absolute;
    top: -10%;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: .1px solid rgba(var(--dark-color), 0.1);
    background-color: var(--dark-color-landing);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
}

.contact_card_email {
    display: flex;
    align-items: center;
    padding-right: 1re;
    position: relative;
    left: -13px;
}