:root {
  --dark-color: 40, 40, 40;
  --dark-color-landing: rgb(222, 221, 247);
  --light-color: 255, 255, 255;

  --accent-three: #a79fe6;
  --accent-four: #deddf7;
  --accent-five: #fecd4c;

  --disabled-color: 240, 239, 241;

  --darker-color: #ffffff ;

  --accent-color: 100, 72, 185  ;
 
  --background-tint: rgba(var(--accent-color), 0.07);

  --border-radius: 0.5rem;
  --font-size: 16px;
  --padding: 1rem;
  --hundred-percent: 100%;

  --entry: entry();
  --view: view();
  --exit: exit();
}

/* Dark Theme */
.dark {
  --dark-color: 255, 255, 255;
  --dark-color-landing: rgb(14, 15, 21);
  --light-color: 40, 40, 40;

  --disabled-color: 240, 239, 241;

  --darker-color: #121212;

  --accent-color:255, 159, 164 ;
  --background-tint: rgba(var(--dark-color), 0.05);
}
