#landing_page_wrapper {
    padding: var(--padding);
    height: 100vh;
    height: 100svh;
    position: relative;
    /* overflow-x: hidden; */
}

.landing_page_main {
    background-color: var(--dark-color-landing);
    border-radius: var(--border-radius);
    /* padding: var(--padding); */
    height: var(--hundred-percent);
    overflow-x: hidden;
    position: relative;

}

.spline_wrapper {
    /* border: solid 1px red; */
    width: 100%;
    height: 100%;
    margin: auto auto;
    border-radius: var(--border-radius);


    /* margin-top: 8rem; */

    &>div {
        border-radius: .5rem;
    }
}

canvas {
    transform: translate(0, 0px);
}

.my_passion {
    font-family: "Poppins", monospace;
    position: absolute;
    top: clamp(10.5rem, 20vw, 16rem);
    left: clamp(12%, 20vw, 14.5%);
    width: 35ch;
    /* font-weight: 300; */
    font-size: clamp(10px, 3vw, 15px);

    text-transform: uppercase;
    overflow: hidden;
}

.time h1 {
    font-family: 'ClashDisplay', Arial, sans-serif;
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: clamp(.2rem, 9vw, 7rem);
    width: max-content;
    text-transform: uppercase;
    mix-blend-mode: overlay;
}

.recent_cover {
    overflow: hidden;
    width: inherit;
}

.recent_work {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;


    position: absolute;
    width: calc(100% - 2rem);
    bottom: 0;
    z-index: 3;
    transition: all 1s linear;

    & .h_cover {
        overflow: hidden;
        width: max-content;
        font-size: clamp(10px, 2vw, 1rem);
    }

    & h6 {
        white-space: nowrap;
        font-size: clamp(.6rem, 4vw, 1rem);

    }


    & .dial {
        --percentX: 200%;
        --percentY: 250%;
        width: 240px;
        padding: 1rem;

        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(80%);
        /* border: red 1px solid; */
        transition: transform .3s linear;
        will-change: transform;

        & .p_wrapper {
            position: absolute;
            margin: 0;
            font-size: .9rem;
            color: rgba(var(--dark-color), .7);
            cursor: pointer;

            /* border: 1px solid red; */
            padding: .5rem;
            /* transition: transform 1s linear; */
            overflow: hidden;

            & p {
                margin: 0;
                transition: transform .5s linear;
            }
        }

        & .p_wrapper:nth-child(1) {
            transform: translateX(calc(-1 * var(--percentX)));
        }

        & .p_wrapper:nth-child(2) {
            transform: translateX(calc(var(--percentX) * 0.88));
        }

        & .p_wrapper:nth-child(3) {
            transform: translateY(var(--percentY));
        }

        & .p_wrapper:nth-child(4) {
            transform: translateY(calc(-1 * var(--percentY)));
        }
    }

}

.recent {
    width: clamp(100px, 25vw, 240px);
}

.recent>img {
    aspect-ratio: 16 / 14;
    /* Height is auto-calculated */
    overflow: hidden;
    cursor: pointer;
    will-change: transform;
    transition: transform .3s linear;
}

.recent:hover>img {
    transform: scale(.95);
}


.frameDiv {
    width: 70%;
    aspect-ratio: 1/1;
    position: relative;
    border: solid rgba(var(--dark-color), .7) 2px;
    border-radius: 50%;


    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (width < 900px) {
    .circleDiv::before {
        top: -1.8rem !important;
    }

}

@media screen and (width < 768px) {
    .dial {

        transform: scale(70%) !important;

        position: relative;
        top: 15px;
        left: 10%;

        & .p_wrapper:nth-child(1) {
            transform: translateX(calc(-1 * var(--percentX) + 30%)) !important;
        }

        & .p_wrapper:nth-child(2) {
            transform: translateX(calc(var(--percentX) - 40%)) !important;
        }

        & .p_wrapper:nth-child(3) {
            transform: translateY(calc(var(--percentY) - 70%)) !important;
        }

        & .p_wrapper:nth-child(4) {
            transform: translateY(calc(-1 * var(--percentY) + 70%)) !important;
        }
    }



}

@media screen and (width < 600px) {
    .dial {
        transform: scale(60%) !important;
    }

}

.pinkDiv {
    position: absolute;
    width: 90%;
    height: 90%;
    opacity: 0.5;
    z-index: 1;
    overflow: visible;

    /* background-color: pink; */
}

.circleDiv {
    /* border: solid darkred 3px; */
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    box-sizing: border-box;
    transition: all .5s linear;
    transform-origin: center;



    &::before {
        content: "";
        position: absolute;
        top: -2.5rem;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 7px;
        height: 7px;
        background-color: rgb(var(--dark-color));
        border-radius: 50%;
    }
}

.go_into_hidding {
    transform: translateY(140%);
    transition: transform .5s linear;
}

.xoom {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.1) rotate(360deg) !important;
    cursor: pointer;
}

.scale_down {
    transform: scale(50%) !important;
    transform-origin: center;

}


.projects-slide-container {
    display: flex;
    transform: translateY(-50%);
}

.projects-card {
    background: rgba(255, 255, 255, 0);
    border-radius: var(--border-radius);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(var(--dark-color), .1);

    cursor: pointer;

    width: clamp(300px, 30vw, 700px);
    height: clamp(180px, 28vw, 500px);

    margin-right: 2rem;

}

.card-body {
    padding: 0.5rem;
    height: 100%;
}

.projects-card-img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
    object-position: center center;
}

.projects-card-footer {
    color: #ffffff;
    margin: .5rem 0;
}

.project-category {
    background-color: #ffffff20;
    padding: .3rem .5rem;
    border-radius: 3px;
}

.project_scroller_cover {
    position: absolute;
    width: calc(100% - 2rem);
    overflow: hidden;
    top: 50%;
    transform: translateY(-50%);

}