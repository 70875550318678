.mySwiper {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out; 
}

.mySwiper img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-slide:not(.swiper-slide-active) {
    opacity: .6;
    filter: blur(5px);
}