.numbered-card-cover {
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius);

}

.numbered-card {
    position: relative;
    width: 100%;
    height: 500px;
    background-color: rgba(var(--dark-color), .05);
    border-radius: var(--border-radius);
    overflow: hidden;
}