[data-animation="fade-in"] {
  animation-timing-function: linear;
  animation-name: fade-in;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateY(50px);
  transform-origin: left;
  animation-timeline: view();
  animation-range: 25% 40%;
  animation-duration: 1ms;
}

@keyframes fade-in {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
