@import url('variable.scss');
@import url('animation.scss');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');


/* ---------------------------
Global Styles
--------------------------- */
@font-face {

  /* This is a local font family */
  font-family: 'ClashDisplay';
  src: url('./assets/fonts/ClashDisplay-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



body {
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-size: .8rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;
  background-color: rgb(var(--light-color));
  color: rgba(var(--dark-color), .9) !important;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: var(--hundred-percent);
  height: var(--hundred-percent);
  height: 100dvh;
  height: 100svh;
  will-change: scroll-position;
  counter-reset: counter 00;


  font-smooth: always;
  word-spacing: 5px;

  /* For Safari */
  -webkit-font-smoothing: subpixel-antialiased;
  /* For Webkit-based browsers */
  /* -moz-osx-font-smoothing: grayscale; */
  /* For Firefox on macOS */
  text-rendering: optimizeLegibility;
  /* Improves kerning and rendering for web fonts */
}


*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  /* font-style: normal; */
  font-variation-settings:
    "wdth" 100;
  font-weight: 700;
  -webkit-font-smoothing: subpixel-antialiased;
}

.p,
p,
li,
figcaption {
  /* text-wrap: balance; */
  text-wrap: pretty;
  font-family: "Poppins", sans-serif;
}

::before,
::after {
  font-family: "Poppins", sans-serif;
}

a {
  color: rgb(var(--dark-color));
  text-decoration: none;
  font-family: "Space Mono", monospace;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
  font-weight: 500;

  &:hover {
    color: rgba(var(--dark-color), .8);
  }
}

img,
svg {
  image-rendering: optimizeSpeed;
  image-rendering: crisp-edges;
  -webkit-backface-visibility: hidden;
  /* Helps with rendering smoothness */
}

.d-none {
  visibility: hidden;
}


[data-animate-in] {
  animation: animate-in 1s ease forwards;
  animation-delay: 2.8s;
  opacity: 0;
}

@keyframes animate-in {
  from {
    opacity: 0;
    transform: scale(.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}


[data-animate-fromtop] {
  animation: animate-in-top 1s ease forwards;
  animation-delay: 2.8s;
  opacity: 0;
  transform: translateY(-100%);
}

@keyframes animate-in-top {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.foreign-object {
  width: 200px;
  height: 100px;
  color: rgb(var(--dark-color));
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  word-wrap: break-word;
}

.hover-target {
  cursor: pointer;
}

.forced-white {
  fill: white !important;
  letter-spacing: 0px;
  white-space: pre;
}