.modal_cover {
    /* background-image: url("https://www.transparenttextures.com/patterns/always-grey.png"); */


    /* background-blend-mode: exclusion; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-height: 83vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    background-color: var(--darker-color);
    color: rgb(var(--dark-color));

    border: 1px solid rgba(var(--dark-color), .1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.24);
    border-radius: var(--border-radius);
    /* padding: 1rem; */
}

.modal-font>* {
    font-family: 'ClashDisplay', sans-serif !important;
}