.card_style {
    /* min-width: 615px; */
    height: 100%;
    /* From https://css.glass */
    background: transparent;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border: .8px solid rgba(var(--dark-color), .1);
    -webkit-backdrop-filter: blur(20px);
    border-radius: var(--border-radius);
    will-change: scale, transform;
    color: rgb(var(--dark-color));

    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    overflow-y: hidden;
    transition: transform .3s linear;

    & p {
        margin: 0;
        text-transform: uppercase;
        will-change: scale, transform;
    }

    &:hover {
        transform: scale(.98);
    }
}





.card_style:hover .card_style_header {
    transform: translateY(0);
}


.card_style_header {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem;

    transform: translateY(-200%);
    transition: transform 500ms ease;

    & .card_style_header_content p:first-child {
        color: rgba(var(--dark-color), .9);
    }
}



.card_style_footer {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem;


    & .card_style_footer_content p:first-child {
        color: rgba(var(--dark-color), .9);
    }
}

@media screen and (width < 768px) {
    .card_style_footer {
        margin: .8rem 1.5rem;
        font-size: .6rem;
    }

    .card_style_header {
        margin: .8rem 1.5rem;
        font-size: .6rem;
    }
}

.card_style_inner {
    width: 85%;
    height: 60%;
    /* border: red solid ; */
    margin: auto;
    margin-top: 0;

    & img {
        width: 100%;
        max-height: clamp(200px, 16vw, 300px);
        object-fit: cover;
        object-position: top left;
    }


}