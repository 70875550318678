h3 {
    /* margin-bottom: 2rem; */
}

.title {
    font-size: clamp(2rem, 15vw, 5rem);
}

.symbol {
    font-size: 4rem;
    color: rgb(var(--accent-color));
    position: relative;
    top: -60%;
}

/*--- Nav Scrolling Offset ---*/
.offset:before {
    height: 4rem;
    margin-top: -4rem;
    content: "";
    display: block;
}


/*============ NAVIGATION ============*/
.navbar {
    padding: 15px;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.1rem;
    transition: background-color 0.5s ease 0s;
}

.navbar-brand img {
    height: 3rem;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
    color: rgb(var(--dark-color));
    background-image: linear-gradient(to bottom,
            #e6bc24,
            #eab324,
            #edab26,
            #f0a22a,
            #f2992e);
    padding: 15px;
    transition: all 1s ease;
}


/*============ LANDING PAGE IMAGE ============*/
.home-inner {
    scale: 1;
}

.btn-lg {
    border-width: medium;
    border-radius: 0;
    padding: 0.8rem 1.5rem;
    font-size: 1.1rem;
    font-weight: 400;
    text-transform: uppercase;
    display: inline-block !important;
}

/*--- Bootstrap Mobile Gutter Fix --*/
.row,
.container-fluid {
    margin-left: 0px !important;
    margin-right: 0px !important;
}



/*--- Fixed Landing Page Section --*/
.landing {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Change height of landing page image. */
    display: table;
    z-index: -1;
}


.home-wrap {
    clip: rect(0, auto, auto, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.home-inner {
    position: fixed;
    /* Change to relative to remove fixed style. */
    display: table;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    will-change: transform;
}

.animate-zoom {
    animation-timeline: scroll();
    animation-range: 0 5%;
    animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: zoom;
    animation-name: zoom;
}


@keyframes zoom {
    to {
        transform: scale(3);
        opacity: 0;
    }
}

@-webkit-keyframes zoom {

    /* For older Safari and Chrome */
    to {
        -webkit-transform: scale(3);
        opacity: 0;
    }
}

.down-arrow {
    left: 50%;
    bottom: 50px;
    z-index: 1;
    position: absolute !important;
    cursor: pointer;


}

/* mouse css  */

.mouse-btn {
    margin: 10px auto;
    width: 40px;
    height: 60px;
    border: 3px solid rgba(var(--dark-color), .9);
    border-radius: 20px;
    display: flex;
}


.mouse-scroll {
    display: block;
    width: 20px;
    height: 20px;
    background: linear-gradient(170deg, rgba(var(--dark-color), .3), rgb(var(--dark-color)));
    border-radius: 50%;
    margin: auto;
    animation: scrolling13 1s linear infinite;
}

@keyframes scrolling13 {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(20px);
    }
}


/* mouse css end  */

/*--- iOS Fixed Background Image --*/
.fixed-background {
    position: relative;
    width: 100%;
    padding: 0 0 10rem 0;
}

.fixed-wrap {
    clip: rect(0, auto, auto, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--dark-color), .05);
    z-index: -999 !important;
}

/*--- Features Dark Background Image --*/

.down-arrow svg.svg-inline--fa {
    opacity: 0.3;
    /*arrow opacity*/
    font-size: 2rem;
    /*arrow size*/
}

.down-arrow svg.svg-inline--fa:hover {
    opacity: 0.5;
    /*arrow hover opacity*/
}


.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
    display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    background: none;
    color: rgb(var(--dark-color));
    border: none;
    padding: 0 !important;
    font: inherit;
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none;
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: rgb(var(--light-color));
    display: block;
    backface-visibility: visible;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: rgba(var(--dark-color), .2);
}

/*============ FEATURES SECTION ============*/

.btn-sm {
    border-width: medium;
    border-radius: 0;
    padding: 0.5rem 1.1rem;
    font-size: 0.9rem;
    font-weight: 400;
    text-transform: uppercase;
    margin: 1rem;
}

.btn-orange {
    border-color: #f2992e;
    background-color: #f2992e;
    color: rgb(var(--dark-color));
}

.btn-orange:hover,
.btn-orange:focus {
    border-color: #f79928;
    background-color: #f79928;
    color: rgb(var(--dark-color));
}

.jumbotron {
    border-radius: 0;
    /* padding: 1rem 0 5rem; */
}

h3.heading {
    font-size: clamp(1.5rem, 5vw, 5rem);
    text-transform: uppercase;
    margin: 4rem 0;
    /* margin: auto; */
    text-align: center;
    /* width: 100%; */
    font-weight: 500;
    font-style: normal;
}

.heading-left {
    font-size: clamp(3rem, 10vw, 7rem);
    font-optical-sizing: auto;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    text-align: left;
    margin-bottom: 3rem;
}

.heading-adjust {
    transform: translateY(-90px);
}

@media (width < 768px) {
    .heading-adjust {
        transform: translateY(-60px);
    }
}


[data-cs-main-heading="Design Timeline"] {
    transform: translateY(-40%);
}


.heading-underline-left {
    width: 0;
    height: 0.2rem;
    background-color: rgb(var(--dark-color));
    animation: line-grow linear forwards;
    animation-timeline: view();
    animation-range: 100px 300px;
    transform-origin: left;
}

@keyframes line-grow {
    to {
        width: 4rem;
    }
}

.feature {
    text-align: center;
}

.circle {
    background-color: transparent;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.margin-left {
    margin-left: 10px;
}

.feature h3 {
    font-size: 1.3rem;
    text-transform: uppercase;
    padding-bottom: 0.4rem;
}

.feature p {
    font-size: 1.1rem;
}

/*============ FIXED BACKGROUND IMAGE DARK ============*/
#fixed {
    background-color: rgba(var(--dark-color), .06);
    /* z-index: -1; */
    opacity: rgba(var(--dark-color), .06);
    -webkit-opacity: rgba(var(--dark-color), .06);
    /* opacity: var(--fixed-bg-opacity); */
}

.dark {
    z-index: 1000 !important;
}


/*============ PORTFOLIO SECTION ============*/

.portfolio-item img:hover {
    transform: scale(1.3);
    cursor: zoom-in;
}

.portfolio-item img {
    transition: transform 0.4s ease;
}

.portfolio-item {
    overflow: hidden;
}


/*============ TOP SCROLL ============*/
.top-scroll svg.svg-inline--fa {
    font-size: 3rem;
    height: 1.7rem;
    color: rgba(57, 63, 70, 0.65);
    background-color: #fff;
}

.svg-text,
.design-timeline tspan {
    fill: #fff !important;
    font-weight: 400;
}

.svg-text,
tspan {
    fill: rgb(var(--dark-color)) !important;
    font-weight: 400;
}

a.top-scroll {
    right: 1.2rem;
    bottom: 1.2rem;
    position: fixed;
    opacity: 0.3;
    z-index: 2000;
}

a.top-scroll:hover {
    opacity: 0.5;
}

/*============ MEDIA QUERIES ============*/
@media (max-width: 991px) {
    .caption h1 {
        letter-spacing: 0.2rem;
        padding-bottom: 0.8rem;
    }

    .caption h3 {
        font-size: 2.2rem;
        padding-bottom: 1.7rem;
    }

    .btn-lg {
        padding: 0.7rem 1.2rem;
        font-size: 1rem;
    }

    .narrow h2 {
        font-size: 2.1rem;
    }
}

@media (max-width: 767px) {
    .caption h1 {
        letter-spacing: 0.15rem;
        padding-bottom: 0.5rem;
    }

    .caption h3 {
        font-size: 1.7rem;
        padding-bottom: 1.2rem;
    }

    .btn-lg {
        padding: 0.6rem 1.1rem;
        font-size: 1rem;
    }

    .narrow h2 {
        font-size: 1.8rem;
    }

    .clients img {
        max-width: 50%;
        margin: 0 auto;
    }

    .top-scroll {
        display: none !important;
    }
}

@media (max-width: 575px) {


    .caption h1 {
        letter-spacing: 0.1rem;
        padding-bottom: 0.4rem;
    }

    .caption h3 {
        font-size: 1.2rem;
        padding-bottom: 1rem;
    }

    .btn-lg {
        padding: 0.4rem 0.9rem;
        font-size: 0.9rem;
    }

    .narrow {
        width: 85%;
    }
}

@keyframes bounceInUp {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    60% {
        transform: translateY(30px);
    }

    75% {
        transform: translateY(60px);
    }

    90% {
        transform: translateY(1px);
    }

    to {
        transform: translateY(0);
    }
}

[data-animation="bounceInUp"] {
    animation: bounceInUp 1s;
}

[data-delay="5s"] {
    animation-delay: 5s;
}

[data-delay="10s"] {
    animation-delay: 10s;
}

[data-delay="15s"] {
    animation-delay: 15s;
}

.scroller {
    /* width: fit-content; */
    /* outline: 3px solid lime; */
    overflow: hidden;
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    -webkit-mask: linear-gradient(90deg,
            transparent,
            white 20%,
            white 80%,
            transparent);
}

.scroller_content {
    display: flex;
    gap: 10px;
    width: max-content;
}

.scroller_content[data-animated="true"] {
    display: flex;
    gap: 10px;
    width: max-content;
    animation: scroll 20s linear infinite;
}

.scroller_item {
    width: clamp(500px, 10vw, 600px);
    height: 100px;
    background-color: rgb(var(--dark-color));
}


@keyframes scroll {
    to {
        transform: translate(calc(-50% - 7px));
    }
}

.bubble-container {
    position: absolute;
    padding-block: 1rem;
    margin-block: 1rem;
    margin: 1rem 0;
    width: 100%;
    border-radius: 5px;
    pointer-events: none;
    height: 100%;
    top: -4rem;
    left: -26px;
    overflow-x: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}

.bubble {
    --pixel: 200px;
    position: absolute;
    width: var(--pixel);
    height: var(--pixel);
    text-align: center;
    font-size: small;
    display: flex;
    padding: 2rem;
    visibility: collapse;
    /* transform: translateX(600px); */
    justify-content: center;
    align-items: center;
    animation: float 20s linear forwards infinite;
}

@media screen and (width < 990px) {
    .bubble-container {
        top: -12rem !important;
    }

}

@media screen and (width < 768px) {
    .bubble-container {
        top: -2rem !important;
    }

}

/* Keyframes for floating effect */
@keyframes float {
    0% {
        scale: 0.5;
        transform: translateX(800px);
    }

    25% {
        visibility: visible;
        transform: translateX(100px);
    }

    50% {
        transform: translateX(0px);
        scale: 1.5;
    }

    75% {
        scale: 1;
    }

    100% {
        scale: 0.5;
        transform: translateX(-800px);
    }
}

/* Different animation delays for each bubble */

.bubble:nth-child(1) {
    animation-delay: 5s;
}

.bubble:nth-child(2) {
    visibility: hidden;
    animation-delay: 10s;
}

.bubble:nth-child(3) {
    visibility: hidden;
    animation-delay: 20s;
}

.bubble:nth-child(4) {
    visibility: hidden;
    animation-delay: 30s;
}

.bubble:nth-child(5) {
    visibility: hidden;
    animation-delay: 40s;
}

.bubble:nth-child(6) {
    visibility: hidden;
    animation-delay: 50s;
}

/* table element design */

[data-type="direct"] {
    background-color: rgba(var(--accent-color), .1);
    padding: 0.5rem 2rem;
    border-radius: 0.2rem;
    line-height: 3;
    white-space: nowrap;
    /* color: rgb(var(--dark-color)); */
}

[data-type="indirect"] {
    background-color: #2c2c2c15;
    padding: 0.5rem 2rem;
    border-radius: 0.2rem;
    line-height: 3;
    white-space: nowrap;
    /* color: rgb(var(--dark-color)); */
}



.table-cover {
    background: var(--accent-four);
    border-radius: calc(var(--border-radius) - .3rem);
    font-family: "poppins", sans-serif;
    font-size: 1rem;
    font-weight: 100;
}


.full_length {
    background-color: rgb(var(--dark-color));
}

.table td {
    min-width: 200px !important;
    font-size: 12px;
    padding: 1.5rem 2.5rem;
}

.table th {
    padding: 1.5rem;
    color: var(--accent-three) !important;
}

.table>:not(caption)>*>* {
    background-color: rgb(var(--light-color));
    border-bottom-width: 0;
    color: rgb(var(--dark-color));
}


/* Research Analysis CSS */
.mag_glass {
    overflow: hidden;
    width: max-content;
    z-index: 10;
    position: relative;
}

.mag_glass img {
    width: clamp(200px, 30vw, 400px);
}

/* Research Summary Section */

.rs_display {
    padding: 0 10rem;

    @media screen and (width < 1173px) {
        padding: 0 5rem;
    }

    @media screen and (width < 1000px) {
        padding: 0;
    }
}

.rs_cover {
    position: relative;
}

/* sectional css  */

.offset {
    padding: 2rem 0;
}

.competitive_audit {
    padding: 1rem;
    background-color: rgba(var(--dark-color), .06);
}

.right_content {
    align-items: flex-start;
    display: flex;
    gap: normal 8px;
    line-height: 27px;
    margin: 0 0 4.5rem 0;
    text-align: left;

    & span {
        color: rgb(var(--accent-color));
    }

}

.design_goal {
    position: relative;
    margin-bottom: 5rem;
    background-color: rgba(var(--dark-color), .06);
    pointer-events: none;
}

.circle_wrapper {
    display: flex;
    border: .05px solid var(--accent-four);
    align-items: center;
    justify-content: center;
    position: relative;

    padding: 10rem 0;
}

.circle {
    width: clamp(10rem, 35vw, 26rem);
    height: clamp(10rem, 35vw, 26rem);
    aspect-ratio: 1;
    background-color: red;
    border-radius: 50%;
    padding: 5rem;
    text-align: center;
    font-size: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: rgb(var(--dark-color));
}

.circle:nth-child(1) {
    background-color: #ffc81560;
    z-index: 1;
    position: relative;
    right: -5%;


    &:before {
        content: "The User";
        font-size: 1.5rem;
        position: absolute;
        top: 50%;
        left: -10%;
        transform: translate(-50%, -50%) rotate(-90deg);
        color: rgb(var(--dark-color));
    }

}




.circle:nth-child(2) {
    position: relative;
    background-color: #9747ff60;

    &:before {
        content: "The Business";
        font-size: 1.5rem;
        position: absolute;
        top: -15%;
        color: var(--text-color);
    }
}

.circle:nth-child(3) {
    position: relative;
    background-color: #88fc7660;
    left: -5%;

    &:before {
        content: "The Product";
        font-size: 1.5rem;
        position: absolute;
        top: 50%;
        right: -50%;
        transform: translate(-50%, -50%) rotate(90deg);

        color: var(--text-color);
    }
}


@media screen and (width < 1600px) {
    .circle {
        scale: .8;
    }

    .circle:nth-child(1) {
        right: -10%;

    }

    .circle:nth-child(2) {}

    .circle:nth-child(3) {
        left: -10%;
    }

    .circle_wrapper {
        padding: 1rem 0
    }
}




@media screen and (width < 1200px) {

    .circle {
        font-size: small;
        scale: 1.2;
    }

    .dangerous-text {
        width: 20ch;
    }



    .circle_wrapper {
        flex-direction: column;
        padding: 3rem 0 0 0;
        gap: 5rem;
    }

    .circle:nth-child(1) {
        position: relative;
        left: 0;
        transform: translateY(0) !important;
        z-index: 0;


        &:before {
            font-size: 1rem;

            position: absolute;
            left: 10%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(270deg);
        }
    }

    .circle:nth-child(2) {
        position: relative;
        transform: translateY(-30%) !important;
        z-index: 0;

        &:before {
            font-size: 1rem;

            position: absolute;
            left: 10%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(270deg);
        }
    }

    .circle:nth-child(3) {
        position: relative;
        transform: translateY(-59%) !important;
        left: 0;
        z-index: 0;

        &:before {
            font-size: 1rem;

            position: absolute;
            left: 10%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(270deg);
        }
    }
}

@media screen and (width < 600px) {
    .circle {
        font-size: .6rem !important;
    }

    .circle p {
        width: "30ch" !important;
    }
}


.key_features {
    margin: 3rem auto;
}

.short_intro {
    display: inline-flex;
    padding: 5px 15px;
    border-radius: 30px;
    background: var(--background-tint);
    color: rgb(var(--dark-color));
    font-weight: 600;
    margin: auto auto 3rem;
}

.reflections {
    background-color: rgba(var(--dark-color), .06);
}

[data-bs="add"] {
    position: fixed !important;
    bottom: 3rem;
    right: 10px;
    width: 40px !important;
    height: 40px !important;
}

/* sectional css ends  */

.speech_bubble {
    background-color: rgba(var(--dark-color));
    border-radius: 10px;
    padding: 1rem .8rem;
    /* width: clamp(200px, 20vw, 500px); */
    color: rgba(var(--light-color));
}

.speech_bubble_svg {
    fill: rgba(var(--dark-color));
    bottom: 0;
    left: -20px;
    position: absolute;
}

left-heading,
.right-heading,
.speech_bubble {
    position: relative;
}

/* Spline Css  */

.display-area {
    position: relative;
    left: 10rem;
    width: calc(100% - 10rem);
    padding: 1rem;
    transition: all .3s ease;
}

@media screen and (width < 990px) {
    .display-area {
        width: 100%;
        left: 0;
    }

    .threeD-container {
        display: flex !important;
        flex-direction: column;

    }
}

.simple-list-item {
    height: calc(100vh - 10rem);
    margin: 0 0 6rem 0;
}

#simple-list-example {
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    left: 100px;
    padding: 24px;
    position: fixed;
    top: 200px;
    width: clamp(100px, 15vw, 210px);
    z-index: 10;

    & a {
        color: inherit;
        text-decoration: none;
        padding: 12px;
        transition: background-color 0.3s ease;
    }
}

.threeD-image {
    position: relative;
    height: 80%;
    align-self: flex-end;

    display: flex;
}


.threeD-container {
    /* border: 1px solid red; */

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr;
    gap: 1rem;
}

.threeD-col {
    grid-row: span 2;
    border-radius: var(--border-radius);
    overflow: hidden;

    background-image: radial-gradient(rgba(12, 12, 12, 0.05) 2px, transparent 0);
    background-size: 30px 30px;

    width: 100%;
    height: 100%;
}


/* NEW CSS  */

[data-cs-main-heading],
[data-cs-section-heading],
[data-cs-enlarged-callout],
[data-cs-quoted],
[data-cs-normalized] {
    font-family: 'poppins';
    -webkit-font-smoothing: subpixel-antialiased;
}

.highlight {
    font-size: clamp(1rem, 7vw, 2.5rem);
    font-weight: 600;
    color: rgba(var(--accent-color), 0.4);
}

.circle-dp {
    position: relative;


    &&::after {
        content: "";
        position: absolute;
        width: 10vw;
        height: 1px;
        top: 50%;
        left: 120%;
        border: 1px dashed var(--accent-four);
        transform: translateY(-50%);
    }
}

.dp-sec {
    width: 84%;
    margin: auto;
}


[data-cs-main-heading] {
    font-size: clamp(2rem, 13vw, 6rem);
    font-weight: 600;
    /* margin: 1rem 0; */
    text-transform: uppercase;
    position: relative;
    line-height: 1;
    /* margin: 8rem 0; */


    &::before {
        content: attr(data-cs-main-heading);
        position: absolute;
        top: -60%;
        left: -1%;
        font-size: clamp(1.5rem, 13vw, 7.5rem);
        color: rgba(var(--dark-color), .03);
        text-transform: uppecase;
        white-space: nowrap;
    }

    &::after {
        counter-increment: counter;
        content: "." "0" counter(counter);
        position: absolute;
        top: -60%;
        right: 0;
        font-size: clamp(1.5rem, 13vw, 7.5rem);
        color: rgba(var(--dark-color), .03);
        text-transform: capitalize;

    }

    & span:nth-child(1) {
        font-weight: 200;
    }

}

[data-cs-section-heading] {
    font-size: clamp(.6rem, 3vw, 1.15rem);
    white-space: nowrap;
    opacity: .65;
    margin: 0 0 2rem 0;
    text-transform: uppercase;
    font-weight: 400;
    color: rgb(var(--accent-color)) ;

    &::before {
        content: attr(data-cs-section-heading);
        margin: 0 .5rem 0 0;
        white-space: nowrap;
    }
}

[data-cs-enlarged-callout] {
    font-size: clamp(1rem, 7vw, 2.3rem);
    line-height: 1.5;
    font-weight: 200;
    margin: 4rem 0 6rem 0;

    &::before {
        content: "";
    }
}

[data-cs-quoted] {
    color: rgb(var(--accent-color));

    &::before {
        content: open-quote;
        font-size: 2rem;
        font-family: cursive;
    }

    &::after {
        content: close-quote;
        font-size: 2rem;
        font-family: cursive;
    }
}

[data-cs-normalized] {
    font-size: clamp(.5rem, 4vw, 1.2rem);
    line-height: 1.6;
    font-weight: 300;

    &::before {
        content: "";
    }
}

#sectionOne,
#sectionTwo,
#sectionThree,
#sectionFour,
#sectionFive {
    padding: 0 1rem;
}

#sectionThree,
#sectionTwo {
    padding: 0;
}

#sectionTwo {
    background-color: rgb(var(--light-color));
}

#sectionThree {
    /* transform: translateY(-20%); */
}

@media screen and (width < 1200px) {
    [data-cs-main-heading] {


        &::before {
            content: "";


        }

        &::after {

            content: " "

        }



    }

    .dp-sec {
        width: 100%;
        padding: 0;
    }
}