.projects_slide {}

.scroller_container {
    width: 100%;
    height: 50vh;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.swiper {
    overflow-y: visible;
    --webkit-image-mask: linear-gradient(90deg, transparent, var(--dark-color-landing) 10%, var(--dark-color-landing) 90%, transparent);
    mask: linear-gradient(90deg, transparent, var(--dark-color-landing) 10%, var(--dark-color-landing) 90%, transparent);
}

.swiper-slide:not(.swiper-slide-active) {
    opacity: 1;
    filter: unset;
}

.swiper-slide {
    background: transparent !important;
}



.button_style_cover {
    overflow: hidden;
    position: absolute;
    bottom: 10%;
    left: 0;
    padding: 2rem;
    transition: bottom .5s linear;

}

.button_style {
    display: flex;
    position: relative;
    gap: 1rem;

    & button {
        background-color: transparent;
        bottom: 10%;
        z-index: 10;
        border: 1px solid rgba(var(--dark-color), .3);
        border-radius: var(--border-radius);
        width: 50px;
        height: 50px;
        cursor: pointer;
        color: rgb(var(--dark-color));
    }
}

@media screen and (width < 1500px) {
    .button_style_cover {
        bottom: 20%;
    }
}

@media screen and (width < 1200px) and (width > 650px) {
    .button_style_cover {
        bottom: 21%;
    }

    .button_style {

        & button {
            background-color: rgba(var(--dark-color), .1);
            color: rgb(var(--dark-color));
        }
    }
}

@media screen and (width < 768px) and (width > 650px) {
    .button_style_cover {
        bottom: 25%;
        padding: 1rem;
    }

    .button_style {

        & button {
            background-color: rgba(var(--dark-color), .1);
            color: rgb(var(--dark-color));
        }
    }
}

@media screen and (width < 400px) {
    .button_style_cover {
        bottom: 26%;
        padding: 1rem;
        background-blend-mode: darken;
    }

    .button_style {

        & button {
            background-color: rgba(var(--dark-color), .1);
            color: rgb(var(--dark-color));
        }
    }


}

@media screen and (width < 380px) {
    .button_style_cover {
        bottom: 3%;
    }
}
