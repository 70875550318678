.sliding-box {
    display: flex;
}

.box-area {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    width: 70px;
    height: 300px;
    margin-right: 20px;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    transition: all linear 0.6s;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.active {
        width: 100% !important;
    }
}

.box i {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    background: rgb(var(--light-color));
    position: absolute;
    bottom: 20px;
    left: 5px;
    color: rgb(var(--dark-color));
    font-size: 20px;
}

.box:last-child {
    margin-right: 0;
}

.box::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(var(--dark-color));
}



.box h2 {
    position: absolute;
    left: 10px;
    font-size: clamp(18px, 5vw, 25px);
    color: rgb(var(--light-color));
    font-weight: 500;
    opacity: 0;
    transition: all linear 0.3s;
}



.box.active h2 {
    opacity: 1;
}