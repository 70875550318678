.switch {
    display: inline-block;
    height: 26px;
    left: -5px;
    position: absolute;
    transform: rotate(90deg) !important;
    width: 65px;
    z-index: 10;


    & .switch input {
        height: 0;
        opacity: 0;
        width: 0;
    }

    & .slider {
        background-color: rgb(var(--light-color));
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: transform .4s;
        -webkit-transition: .4s;

        border-top-left-radius: .5rem;
        border-top-right-radius: .5rem;
    }

    & .slider:before {
        background-color: rgb(var(--dark-color));
        bottom: 4px;
        content: "";
        height: 14px;
        left: 15px;
        position: absolute;
        /* transition: position .4s; */
        -webkit-transition: .4s;
        width: 14px;
        border-radius: var(--border-radius);
    }

    & input:checked+.slider {
        background-color: rgb(var(--light-color));
    }

    & input:checked+.slider:before {
        transform: translateX(20px);
        -ms-transform: translateX(20px);
        -webkit-transform: translateX(20px);
    }

    & input:focus+.slider {
        box-shadow: 0 0 1px rgb(var(--light-color));
    }
}

.switch_top_curve {
    background-color: rgb(var(--light-color));
    height: 27px;
    left: -17px;
    position: absolute;
    top: -1px;
    width: 17px;

    & .switch_top_curve_inner {
        background-color: var(--dark-color-landing);
        border-bottom-right-radius: .5rem;
        height: 100%;
        position: relative;
        top: -1.5px;
        width: 100%;
        /* z-index: 1; */
    }
}

.switch_bottom_curve {
    background-color: rgb(var(--light-color));
    height: 27px;
    left: 65px;
    position: absolute;
    top: -1px;
    width: 17px;

    & .switch_bottom_curve_inner {
        background-color: var(--dark-color-landing);
        border-bottom-left-radius: .5rem;
        height: 100%;
        position: relative;
        top: -1.5px;
        width: 100%;
        z-index: 1;
    }
}